<template>
  <v-row class="justify-center align-center">
    <v-col cols="4">
      <v-img  src="../assets/404.svg"></v-img><br/>
      <p class="text-center headline">La page demandée n'existe pas</p><br/>
      <p class="text-center"><router-link to="/profile">Retour en zone connue...</router-link></p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: '404',
};
</script>
